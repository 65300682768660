<template>
  <div class="dizme_tm_header">
    <div class="container">
      <div class="inner">
        <div class="logo">
          <a href="#"
            ><img :src="`img/logo/${dark ? 'dark' : 'logo'}.png`" alt="ქორწილის ვიდეოგრაფი ზუგდიდში"
          /></a>
        </div>
        <div class="menu">
          <ul class="anchor_nav">
            <li class="current"><a href="#მთავარი">მთავარი</a></li>
            <li><a href="#ჩემს_შესახებ">ჩემს შესახებ</a></li>
            <li><a href="#პორტფოლიო">პორტფოლიო</a></li>
            <li><a href="#მომსახურება">მომსახურება</a></li>
            <li><a href="#ბლოგი">ბლოგი</a></li>
            <li><a href="#კონტაქტი">კონტაქტი</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderVue",
  props: {
    dark: { type: Boolean },
  },
};
</script>
