import { createRouter, createWebHistory } from "vue-router";
import { useHead } from "@vueuse/head"; // Meta-ს მართვა

import IndexDarkView from "../views/IndexDarkView.vue";
import IndexView from "../views/IndexView.vue";
import IntroView from "../views/IntroView.vue";
import WeddingDay from "../components/MyPages/WeddingDay.vue";
import VideoPage from "../components/MyPages/VideoPage.vue";

const routes = [
  {
    path: "/",
    name: "IndexDarkView",
    component: IndexDarkView,
  },
  {
    path: "/light",
    name: "Index",
    component: IndexView,
  },
  {
    path: "/intro",
    name: "IntroView",
    component: IntroView,
  },
  {
    path: "/Wedding-Day",
    name: "WeddingDay",
    component: WeddingDay,
  },
  {
    path: "/:year/:month/:day",
    name: "VideoPage",
    component: VideoPage,
    props: true, // ვუშვებთ პარამეტრებს
    meta: { title: "Wedding Video", description: "Watch our beautiful wedding video!" },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Meta ტეგების ავტომატურად ჩატვირთვა
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    useHead({
      title: to.meta.title,
      meta: [
        { name: "description", content: to.meta.description }
      ],
    });
  }
  next();
});

export default router;
