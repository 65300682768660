<template>
  <div class="video-page">
    <div v-if="wedding" class="video-container">
      <a href="https://www.valerazhvania.ge/" target="_blank" class="overlay">
        <img src="https://www.valerazhvania.ge/img/logo/dark.png" alt="ლოგო" />
      </a>
      <iframe :src="wedding.videoUrl" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div>
    <div v-else>
      <p>ვიდეო არ მოიძებნა.</p>
    </div>
  </div>
</template>

<script setup>
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import weddings from "@/assets/weddings.json"; // JSON ფაილის იმპორტი

const route = useRoute();
const wedding = ref(null);

onMounted(() => {
  const date = `${route.params.year}-${route.params.month}-${route.params.day}`;
  wedding.value = weddings.find(w => w.date === date);

  if (wedding.value) {
    useHead({
      title: `Wedding - ${wedding.value.couple}`,
      meta: [
        { name: "description", content: wedding.value.description },
        { property: "og:title", content: wedding.value.couple },
        { property: "og:description", content: wedding.value.description },
        { property: "og:image", content: wedding.value.image },
        { property: "og:url", content: `https://valerazhvania.ge/${date}` }
      ],
    });
  }
});
</script>

<style scoped>
.video-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2b2d33;
}

.video-container {
  width: 64%;
  position: relative;
  padding-bottom: 36%;
  border-radius: 20px;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.overlay {
  position: absolute;
  top: 10px;
  right: 8px;
  width: 110px;
  background: #2b2d33;
  border-radius: 10px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding: 5px;
}

.overlay img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
</style>
