import "swiper/css";
// import "swiper/css/pagination";
import { createApp } from "vue";
import { createHead } from "@vueuse/head"; // ✅ დამატება
import App from "./App.vue";
import router from "./router";
import "/public/css/custom.css";
import "/public/css/dark.css";
import "/public/css/plugins.css";
import "/public/css/style.css";

const app = createApp(App);
const head = createHead(); // ✅ head ობიექტის შექმნა

app.use(router);
app.use(head); // ✅ head-ის ჩასმა აპლიკაციაში
app.mount("#app");
